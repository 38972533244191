import { BuiltinCategories } from 'constants/BuiltinCategories';
import { RightBarTabs } from 'constants/RightBarTabs';
import Globals from 'Globals';
import { groupBy, isEmpty, mapValues, maxBy, size, sortBy } from 'lodash';
import { flow } from 'mobx';
import Category from "models/Category";
import Task from 'models/Task';
import TasksList from "models/TasksList";
import TreeNode from "models/TreeNode";
import Stores from 'stores/Stores';
import { WriteBatch } from 'utils/FirebaseInitializedApp';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import i18n from 'utils/i18n';
import { formulaHasVariables } from 'utils/MeasurementFormatter';
import { getMeasurementsWithDependencies, getMeasurementValueAtNode } from 'utils/MeasurementUtil';
import { onAddOrDeleteImage } from 'utils/ProjectUtils';
import { waitOnStoresReady } from 'utils/StoreUtil';
import { TrackedInteractions, trackInteraction } from 'utils/TrackingUtil';
import { getSafe } from 'utils/Utils';
import uuidv4 from 'uuid/v4';
;

// TODO: Evaluate if caching from projects.json could be used
export const copyTasksFromListToProject = flow(function* (
  tasksList: TasksList,
  targetNode: TreeNode,
  targetStores: Stores = getSafe(() => targetNode.stores),
  targetCategory?: Category, // unused
  batchParam?: WriteBatch
) {
  const { listStores, defaultStores } = Globals;
  const { userInfoStore } = targetStores;
  const { tasksListsStore } = defaultStores;
  const batch = batchParam || firestoreBatch(targetStores);

  listStores.commonStore.selectedProjectId = tasksList.id;

  if (tasksListsStore.isCopyingList) {
    return;
  }

  tasksListsStore.isCopyingList = true;

  // don't know the source but tasks store doesnt load correctly when we just created the tasks list
  listStores.tasksStore.attemptLoadItems();

  yield waitOnStoresReady([
    listStores.treeNodesStore,
    listStores.tasksStore,
    listStores.measurementsStore,
    listStores.providingItemsStore,
    listStores.categoriesStore,
  ]);

  const selectedItems = Globals.listDraftStores.tasksStore.selectedItemsArray;

  const tasksListNodeCopy = listStores.treeNodesStore.rootNode.cloneDeep(
    batch,
    targetStores,
    uuidv4(),
    new Map(),
    isEmpty(selectedItems) ? null : selectedItems.map(task => task.id)
    // should eventually skip non master measurements to avoid overwriting them with bad value in tasks list
  );
  tasksListNodeCopy.isRootNode = false;

  if (targetCategory) {
    tasksListNodeCopy.tasks.forEach(task => {
      task.category = targetCategory
    });
    targetStores.tasksStore.batchAddEditItems(tasksListNodeCopy.tasks, batch);
  }

  const tasksListTasksByCateg = mapValues(groupBy(tasksListNodeCopy.ownTasks, 'categoryId'), tasks => sortBy(tasks, 'index'));
  const targetNodeMaxTasksIndexByCateg = mapValues(groupBy(targetNode.ownTasks, 'categoryId'), tasks => maxBy(tasks, 'index').index);

  Object.keys(tasksListTasksByCateg).forEach(categId => {
    tasksListTasksByCateg[categId].forEach((task, taskIndex) => {
      task.index = (targetNodeMaxTasksIndexByCateg[categId] || 0) + taskIndex + 1;
    })
  })

  targetStores.tasksStore.addEditItems(tasksListNodeCopy.tasks, true, ['index'], batch);

  targetNode.ownTasksIds = sortBy(targetNode.ownTasksIds.concat(tasksListNodeCopy.ownTasksIds), 'index');

  const measurementValues = tasksListNodeCopy.ownMeasurementValuesArray
    .filter(measurementValue => (
      measurementValue.measurementId &&
      !targetNode.measurementValues.has(measurementValue.measurementId)
    ));

  measurementValues.forEach(mv => {
    if (mv.measurement?.shouldSetToZeroWhenImportingTasksList) {
      mv.formula = "0";
    } else if (formulaHasVariables(mv.formula) && mv.measurement && !mv.measurement.isOneTimeUse) {
      // always use the latest formula, not the one stored in taskslist
      mv.formula = mv.measurement.defaultFormula;
    }
  })

  targetStores.treeNodesStore.applyMeasurementValues(
    targetNode,
    measurementValues,
    true,
    batch
  );

  // copy image
  if (tasksList.imageUrl && !targetNode.imageUrl) {
    targetNode.imageUrls = [...targetNode.imageUrls, tasksList.imageUrl];
    targetNode.thumbUrls = [...targetNode.thumbUrls, tasksList.thumbUrl || tasksList.imageUrl];

    if (targetNode.imageUrl === tasksList.imageUrl) {
      targetNode.shouldSkipImageInReport = true;
    }

    onAddOrDeleteImage(listStores);
  }

  // copy notes
  if (tasksList.description2 && !targetNode.description2 && userInfoStore.user?.shouldCopyNotesFromTasksLists) {
    targetNode.description2 = tasksList.description2;
  }

  targetStores.treeNodesStore.addEditItem(targetNode, true, ['ownMeasurementValues', 'ownTasksIds', '_imageUrls', '_thumbUrls', 'shouldSkipImageInReport', '__description2'], batch);


  if (!batchParam) {
    // optimistic, show before commited to db
    /*yield*/ batch.commit();
    trackInteraction(TrackedInteractions.ApplyTasksLists);
  }

  tasksListsStore.isCopyingList = false;
  defaultStores.dragAndDropStore.dragObject = null;
});



// NEED TO COMBINE WITH SAVING TASKS LISTS DETAILS DIALOG
// because of how we want to save non master items
// stores should be target stores because will do droptable to overwrite existing taskslist if provided as argument
export const copyTasksFromProjectToList = flow(function* (
  stores: Stores,
  sourceNode = stores.treeNodesStore.selectedTreeNode,
  tasks: Task[] = stores.tasksStore.selectedItemsArray,
  targetTasksList: TasksList = undefined,
  batchParam?: WriteBatch
) {
  const { tasksStore: projectTasksStore, treeNodesStore: projectTreeNodesStore, tasksListsStore, settingsStore } = stores;
  const { listStores } = Globals;

  tasksListsStore.searchQuery = '';

  const batch = batchParam || firestoreBatch(stores);

  // create a temporary node that only has selected tasks and related measurements
  const nodeCopy = sourceNode.clone();
  // O(2)
  const measurements = getMeasurementsWithDependencies(tasks.map(task => task.measurement))
    // not all dependencies are guaranteed to be currently activated
    .filter(measurement => sourceNode.measurements.includes(measurement));

  // flatten measurements (ie. move leaf measurement up to this selected node
  nodeCopy.ownMeasurementValues.clear();
  measurements.forEach(measurement => {
    const rootMeasurementValue = getMeasurementValueAtNode(sourceNode, measurement);
    nodeCopy.ownMeasurementValues.set(
      measurement.id,
      rootMeasurementValue
    )
  });
  nodeCopy.ownTasks = tasks;
  nodeCopy.ownShapesIds.clear();
  nodeCopy.children = [];

  let shouldWaitOnTasksListLoad = true;
  if (!targetTasksList) {
    targetTasksList = new TasksList(stores)
    targetTasksList.name = i18n.t('New list')
    shouldWaitOnTasksListLoad = false;
  }

  if (targetTasksList.categoryId === 'General') {
    targetTasksList.category = tasksListsStore.categoryFilter;
  }
  if (targetTasksList.subcategoryId === 'General') {
    targetTasksList.subcategory = tasksListsStore.subcategoryFilter;
  }

  // copy image
  if (nodeCopy.imageUrl) {
    targetTasksList.imageUrls = [...nodeCopy.imageUrls];
    targetTasksList.thumbUrls = [...nodeCopy.thumbUrls];
  }

  const { commonStore, treeNodesStore, tasksStore, measurementsStore } = listStores;
  commonStore.selectedProjectId = targetTasksList.id;

  if (shouldWaitOnTasksListLoad) {
    yield waitOnStoresReady([treeNodesStore, tasksStore]);
    treeNodesStore.dropTable(batch);
    tasksStore.dropTable(batch);
  }

  // this will ensure root node gets created

  const taskListRootNode = nodeCopy.cloneDeep(
    batch,
    listStores,
    undefined,
    undefined,
    undefined,
    //should actually skip master measurements, but function takes care of giving new ids and updating tasks and node references
    //so just ignoring master measurements when copying back instead
    false,
    // skip providingItems to be able to save only non master items
    true
  );

  taskListRootNode.isRootNode = true;

  targetTasksList.isMultiCategs = Object.keys(listStores.tasksStore.itemsByCateg).length > 1;
  targetTasksList.index = size(tasksListsStore.itemsByCateg[BuiltinCategories.General]);

  tasksListsStore.batchAddEditItem(targetTasksList, batch);
  treeNodesStore.batchAddEditItem(taskListRootNode, batch);

  // not usable now because using a separate providing items store currently means reloading the full list of item again (about 3-4 seconds each time)
  /*const nonMasterProvidingItems = uniqBy(compact(tasks.filter(t => !t.providingItem?.cascadeOrders?.has(0)).map(t => t.providingItem)), i => i.id);
  listStores.providingItemsStore.addEditItems(nonMasterProvidingItems, true, null, batch);
  const nonMasterCategories = uniqBy(compact(tasks.filter(t => !t.category?.cascadeOrders?.has(0)).map(t => t.category)), c => c.id);
  listStores.categoriesStore.addEditItems(nonMasterCategories, true, null, batch);*/


  projectTasksStore.isSelectingWithCheckboxes = false;
  projectTasksStore.selectedItems.clear();

  if (!batchParam) {
    yield batch.commit();

    const approxDelay = settingsStore.settings.selectedRightBarTab === RightBarTabs.LISTS ? 0 : 500;
    settingsStore.settings.selectedRightBarTab = RightBarTabs.LISTS;
    tasksListsStore.tasksListBeingEdited = targetTasksList;

    // HACK.. we don't have the ref yet if the tab is about to be changed. It will take a few seconds.
    // Because the autosizer is a child of a display: none component, its height is 0 and children don't render
    // Proper way to fix would be to move autosizer higher up, but complicated if moving out of GroupedList component
    setTimeout(() => tasksListsStore.listComponentRef?.scrollToItem?.(targetTasksList.index), approxDelay);
  }
})