import firebase from 'firebase/compat/app';
import { FileData, FileDataPart, GenerateContentResult } from "firebase/vertexai";
import Stores from 'stores/Stores';
import { gemini } from './FirebaseInitializedApp';

/* example for multiple questions:
Return the following 2 informations about the document in JSON format {title: string, rotationDegrees: number}. 
Here is the information about the title field:
*/

export const PlanUploadPrompt = 'PlanUploadPrompt'; //use a key for this translation because subjet to lots of tweaks

export const getImagePart = (storageRef: firebase.storage.Reference) => ({
  fileData: {
    mimeType: 'image/jpg',
    fileUri: storageRef.toString()
  } as FileData
})

export const parseJSONFromGeminiResponse = (result: GenerateContentResult) => (
  JSON.parse(result.response.text().replace('```json', '').replace('```', ''))
)

export const generateContent = async (
  stores: Stores,
  prompt: string,
  imagePart: FileDataPart,
  successCallback: (result: string | any) => void,
  errorCallback?: (error: any) => void
): Promise<void> => {
  const { userInfoStore } = stores;

  if (!userInfoStore.user.canUseAI) {
    return;
  }

  try {
    //const tokensCount = await gemini.countTokens([prompt, imagePart]);
    const contentResult = await gemini.generateContent([prompt, imagePart]);
    successCallback(contentResult.response.text());
  } catch (e) {
    debugger;
    errorCallback(e);
  }
}