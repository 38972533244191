import { environment } from 'environment';
import { compact, debounce, deburr, first, fromPairs, memoize, omit, sortBy, toPairs } from 'lodash';
import SerializableBase from 'models/SerializableBase';
import { unregister } from 'registerServiceWorker';
import { modelToPlainObject } from './DeserializeUtils';

export function getSafe<T>(fn: () => T) {
  try {
    return fn() as T;
  } catch (e) {
    return undefined;
  }
}

export function isEmptyMap(map: Map<any, any>) {
  return getSafe(() => !map.size);
}

export const round = (value, numDecimals) => (
  Math.round(value * Math.pow(10, numDecimals)) / Math.pow(10, numDecimals)
);

// avoid flooring a number like 11.99999999999999991 to 11... but otherwise regular floor
export const floorRounded = (value) => (
  Math.floor(round(value, 6))
);

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const isRoundedInteger = (value) => floorRounded(value) === value;

// can't observer null or undefined string, only empty strings
// but sometimes we need another type of empty value
export const OBSERVABLE_UNDEFINED = '__undefined__';

export const shouldUseCache = () => true || !window.location.href.includes('local.evalumo');

export const modelSortFunction = (a, b) => a.index - b.index;

// descending not description
export const modelDescSortFunction = (a, b) => b.index - a.index;

// possibly useful with mobx to force it to listen to every part of an OR expression
// even if first part is true
export const OR = (a, b) => (a || b);

//https://stackoverflow.com/questions/2661818/javascript-get-xpath-of-a-node
export function getXPathFromElement(elm) {
  const originalElement = elm;
  const ownerDocument = elm.ownerDocument;
  var allNodes = document.getElementsByTagName('*');
  for (
    var segs = [];
    elm && elm.nodeType == 1;
    elm = elm.parentNode
  ) {
    if (elm.hasAttribute('id')) {
      var uniqueIdCount = 0;
      for (var n = 0; n < allNodes.length; n++) {
        if (allNodes[n].hasAttribute('id') && allNodes[n].id == elm.id) uniqueIdCount++;
        if (uniqueIdCount > 1) break;
      };
      if (uniqueIdCount == 1) {
        segs.unshift('id("' + elm.getAttribute('id') + '")');
        return segs.join('/');
      } else {
        segs.unshift(elm.localName.toLowerCase() + '[@id="' + elm.getAttribute('id') + '"]');
      }
    } else {
      let classPart = '';
      if (elm.hasAttribute('class') && elm.getAttribute('class').trim() !== 'merchantConfiguratorBorder') {
        classPart = '[contains(@class, "' + elm.getAttribute('class').replace('merchantConfiguratorBorder', '').trim() + '")]';
      }
      let i, sib;
      for (i = 1, sib = elm.previousSibling; sib; sib = sib.previousSibling) {
        if (sib.localName == elm.localName) i++;
      };
      segs.unshift(elm.localName.toLowerCase() + classPart /*? classPart :*/ + '[' + i + ']');
    };
  };

  // simplify path
  let simplePath = null;
  for (let i = 0; i < segs.length && segs.length > 1; i++) {
    const simpleSegs = segs
      // remove the [i] part except for the last item
      .map((seg, index) => index === segs.length - 1 ? seg : seg.replace(/\[[0-9]+\]/, ''))
      // take the last part of the path until we have enough to match
      // slice end param is length... for an array of 2, we have to write slice(1, 2) to get the second items
      .slice(segs.length - i - 1, segs.length);

    simplePath = '//' + simpleSegs.join('/');

    const elements = getElementsFromXpath(ownerDocument, simplePath);
    if (elements.length == 1 && elements[0] == originalElement) {
      break;
    }
  }

  return simplePath?.replace(/contains\(@class, "([^"]+)"\)/g, '@class="$1"');
};

export function getElementsFromXpaths(document: HTMLDocument, xpaths: string[]) {
  return xpaths.map(xpath => getElementsFromXpath(document, xpath)).flat();
}

// supports css path too now
export function getElementsFromXpath(document: HTMLDocument, xpath: string) {
  if (!xpath) {
    return [];
  }

  if (xpath === 'location.href') {
    return [document.location.href];
  }

  // fake css selector when we want inner text not value
  xpath = xpath.replace(/(\:evalumo-text)/, '');

  if (xpath.startsWith('//')) {
    const result = document.evaluate(xpath, document, null, XPathResult.ANY_TYPE, null);

    const nodes = [];
    for (let node = result.iterateNext(); node; node = result.iterateNext()) {
      nodes.push(node);
    }

    return nodes;
  } else {
    return Array.from(document.querySelectorAll(xpath));
  }
}

export function htmlDecode(htmlString) {
  var doc = new DOMParser().parseFromString(htmlString, "text/html");
  return doc.documentElement.textContent;
}

export function getSmallestImageFromSrcSet(srcset = '') {
  return getSafe(() => (
    srcset.split(',').map(src => {
      const srcPair = src.trim().split(' ');
      return [srcPair[0], parseInt(srcPair[1])].sort((a, b) => a[1] - b[1]);
    }).sort((a, b) => a[1] - b[1])[0][0]
  )) || '';
}

export function getInnerTextsFromXpath(document: HTMLDocument, xpath: string, shouldUseOneLinePerItem = false) {
  return getElementsFromXpath(document, xpath)?.map(element => (
    typeof element === 'string' && element ||
    (['OPTION', 'INPUT'].includes(element?.tagName) && !xpath.includes('evalumo-text') && element.value) ||
    element.textContent ||
    element.content ||
    htmlDecode(
      element
        ?.innerHTML // not using innerText because it's stripping spaces
        ?.replace(/<\/?[^>]+(>|$)/g, shouldUseOneLinePerItem ? '\n' : ' ')
        ?.replace(/\t/g, ' ')
        ?.replace(/ +/g, ' ')
        ?.replace(/\n+/g, '\n')
        ?.split('\n').filter(line => line.trim()).join('\n') // remove empty lines
        ?.trim()
    ) ||
    ''
  ));
}

export function getChromeVersion() {
  var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

  return raw ? parseInt(raw[2], 10) : false;
}

export function getInnerTextFromFirstFoundXpath(document: HTMLDocument, xpaths: string[]) {
  return first(compact(xpaths.map(xpath => getInnerTextFromXpath(document, xpath)))) || '';
}

export function getInnerTextFromXpaths(document: HTMLDocument, xpaths: string[], shouldUseOneLinePerItem = false, joinCharacter = ' ', limitToFirstMatch = true) {
  // we currently limit to first found match ([0]) but might not be always what we want
  return compact(xpaths.map(xpath => {
    const result = getInnerTextsFromXpath(document, xpath, shouldUseOneLinePerItem);

    return limitToFirstMatch
      ? result?.[0]?.trim?.() || ''
      : result.join('\n') || '';
  })).filter((match, index) => !limitToFirstMatch || index == 0).join(joinCharacter).trim();
}

export function getInnerTextFromXpath(document: HTMLDocument, xpath: string, shouldUseOneLinePerItem = false, limitToFirstMatch = true) {
  const result = getInnerTextsFromXpath(document, xpath, shouldUseOneLinePerItem)
  return (limitToFirstMatch ? (result[0] || '') : (result.join('\n') || '')).trim();
}

export function proxifyUrl(url: string) {
  return url.includes(environment.proxySuffix)
    ? url
    : url.replace(/\/\/([^/]+)/, `//$1${environment.proxySuffix}`);
}

// try to check for specific behavior before resorting to this function
export function isSafari() {
  return navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome') && !navigator.userAgent.includes('Edg'); // Edg not a typo
}

export function sortObjectKeys(inputObject): any {
  //https://github.com/lodash/lodash/issues/1459
  return fromPairs(sortBy(toPairs(inputObject), 0));
}

export function forceReloadPage() {
  debugger;
  unregister();
  location.reload();
}

export const waitForXSeconds = seconds => new Promise(res => setTimeout(res, 1000 * seconds));

export function memoizeDebounce(func: (...args: any) => any, wait = 0, options: any = {}) {
  var mem = memoize(function () {
    const debounced = debounce(func, wait, options);
    return debounced;
  }, options.resolver);
  const memoizedDebounced = function () { mem.apply(this, arguments).apply(this, arguments) };
  memoizedDebounced.cache = mem.cache;

  return memoizedDebounced;
}

export function removeNonAlphanumericCharacters(value: string) {
  return deburr(value?.replace(/[^0-9a-zA-Z]/g, '_') || '');
}

export function hashCode(str) {
  let hash = 0;
  for (let i = 0, len = str.length; i < len; i++) {
    let chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

// for non critical operations where some variables might be null by the time timeout is called
export function trySetTimeout(callback, timeout) {
  setTimeout(() => { try { callback() } catch (e) { } }, timeout);
}

/**
* sends a request to the specified url from a form. this will change the window location.
* @param {string} path the path to send the post request to
* @param {object} params the parameters to add to the url
* @param {string} [method=post] the method to use on the form
*/

export function post(path, params, method = 'post') {
  // The rest of this code assumes you are not using a library.
  // It can be made less verbose if you use one.
  const form = document.createElement('form');
  form.method = method;
  form.action = path;

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = key;
      hiddenField.value = params[key];

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
}

export const areModelsDifferent = (model1: SerializableBase, model2: SerializableBase): boolean => {
  const fieldsToIgnoreInModelComparisons = ['cascadeOrder', 'cascadeOrders', 'updatedMiliseconds', 'createdMiliseconds', '_isReadonly'];

  // will return *not different if one of the models is null and the other is defined
  // not sure if this is what we want
  return model1 && model2 && ((
    JSON.stringify(omit(modelToPlainObject(model1), fieldsToIgnoreInModelComparisons)) !==
    JSON.stringify(omit(modelToPlainObject(model2), fieldsToIgnoreInModelComparisons))
  ))
}

export const ensureProxified = (url: string, domain: string) => {
  if (!url || !domain) {
    return '';
  }

  return url.includes(environment.proxySuffix.slice(0, 18))
    ? url
    : url.replace(domain, domain + environment.proxySuffix)
};

export const cloneEvent = (e) => {
  if (e === undefined || e === null) return undefined;
  function ClonedEvent() { };
  let clone = new ClonedEvent();
  for (let p in e) {
    let d = Object.getOwnPropertyDescriptor(e, p);
    if (d && (d.get || d.set)) Object.defineProperty(clone, p, d); else clone[p] = e[p];
  }
  Object.setPrototypeOf(clone, e);
  return clone;
}

function rgbParamToHex(c) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

export function rgbToHex(r, g, b) {
  return "#" + rgbParamToHex(r) + rgbParamToHex(g) + rgbParamToHex(b);
}
